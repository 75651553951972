import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/PostLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "what-are-they",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-are-they",
        "aria-label": "what are they permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`What are they?`}</h2>
    <p><em parentName="p">{`TLDR: type + predicate = refinement type`}</em></p>
    <p>{`We have a type called `}<em parentName="p"><strong parentName="em">{`T`}</strong></em>{` and a
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Predicate_(mathematical_logic)"
      }}>{`predicate`}</a>{`
on `}<em parentName="p"><strong parentName="em">{`T`}</strong></em>{` ( `}<inlineCode parentName="p">{`(x: T) => boolean`}</inlineCode>{` ) called `}<em parentName="p"><strong parentName="em">{`P`}</strong></em>{`. `}<br parentName="p"></br>{`
`}{`We can say that `}<em parentName="p"><strong parentName="em">{`P`}</strong></em>{` refines `}<strong parentName="p">{`T`}</strong>{` into a type `}<em parentName="p"><strong parentName="em">{`PT`}</strong></em>{`, where `}<em parentName="p"><strong parentName="em">{`PT`}</strong></em>{` is
a subtype of `}<em parentName="p"><strong parentName="em">{`T`}</strong></em>{` containing all values `}<em parentName="p"><strong parentName="em">{`x`}</strong></em>{` of type `}<em parentName="p"><strong parentName="em">{`T`}</strong></em>{` for which
`}<inlineCode parentName="p">{`P(x) === true`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "sources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sources",
        "aria-label": "sources permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Sources`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/Refinement_type"
          }}>{`Wikipedia`}</a></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`In type theory, a refinement type is a type endowed with a predicate
which is assumed to hold for any element of the refined type.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://goto.ucsd.edu/~pvekris/docs/pldi16.pdf"
          }}>{`Refinement Types for TypeScript`}</a></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`A basic refinement type is a basic type, e.g. number, refined with a
logical formula from an SMT decidable logic.`}<br parentName="p"></br>{`
`}{`For example, the types:`}</p>
          <pre parentName="blockquote" {...{
            "className": "night-owl-no-italics vscode-highlight",
            "data-language": ""
          }}><code parentName="pre" {...{
              "className": "vscode-highlight-code"
            }}><span parentName="code" {...{
                "className": "vscode-highlight-line"
              }}>{`type nat = { v: number | 0 ≤ v }`}</span>{`
`}<span parentName="code" {...{
                "className": "vscode-highlight-line"
              }}>{`type pos = { v: number | 0 < v }`}</span>{`
`}<span parentName="code" {...{
                "className": "vscode-highlight-line"
              }}>{`type natN<n> = { v: nat | v = n }`}</span>{`
`}<span parentName="code" {...{
                "className": "vscode-highlight-line"
              }}>{`type idx<a> = { v: nat | v < len(a) }`}</span></code></pre>
          <p parentName="blockquote">{`describe (the set of values corresponding to) non-negative numbers,
positive numbers, numbers equal to some value n, and valid indexes for
an array a, respectively…`}</p>
        </blockquote>
      </li>
    </ul>
    <style>{`
  .vscode-highlight.vscode-highlight {
    background-color: unset;
    color: unset;
    margin: 0;
  }
`}</style>
    <p>{`Refinement types are not supported on language level by TypeScript, but
they’re a concept from type theory — They’re just math and because of it we
can use them and reap the benefits with just a little `}<del parentName="p">{`hack`}</del>{` effort.`}</p>
    <h2 {...{
      "id": "how-can-i-use-thembrwhat-will-i-gain",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-can-i-use-thembrwhat-will-i-gain",
        "aria-label": "how can i use thembrwhat will i gain permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`How can I use them?`}<br />{`What will I gain?`}</h2>
    <p>{`I’ve refined the `}<strong parentName="p"><em parentName="strong">{`number`}</em></strong>{` into `}<strong parentName="p"><em parentName="strong">{`Even`}</em></strong>{` during my
`}<a parentName="p" {...{
        "href": "https://github.com/hasparus/refinement-types-in-typescript"
      }}>{`Wrocław TypeScript talk`}</a>{`.`}<br parentName="p"></br>{`
`}{`Having a type for even numbers doesn’t sound really useful but the idea stays
the same for real world types with complex names copied from Jira.`}</p>
    <p><em parentName="p">{`We can call the predicate once and if it’s true, we remember this fact in
the type system to avoid accidental errors.`}</em></p>
    <h3 {...{
      "id": "example-time",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-time",
        "aria-label": "example time permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Example time`}</h3>
    <h4 {...{
      "id": "almost-real-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#almost-real-example",
        "aria-label": "almost real example permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Almost real example`}</h4>
    <p>{`Assume we’re building a form in which users can enter a list of emails into
a textarea to send activation links to give their friends write permissions
for the app (let’s say it’s a CMS).`}</p>
    <p>{`We validate these emails once, and if they’re all valid, we save them, and
later, few steps further in the form, we send our activation links. Few days
later, we get a new requirement — the user should be able to edit the data
at the last step of the form. Cool, the form is pretty long, we understand
that it can be useful. Several lines of code and we’re done. We go home
happy about the good code we pushed. Next morning we see a new issue in the
tracker — `}<em parentName="p">{`“Validate emails after the user does final edits”`}</em>{` — We totally
forgot about the validation.`}</p>
    <h4 {...{
      "id": "how-could-we-save-the-day-with-a-refinement-type",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how-could-we-save-the-day-with-a-refinement-type",
        "aria-label": "how could we save the day with a refinement type permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`How could we save the day with a refinement type?`}</h4>
    <ul>
      <li parentName="ul">{`Create a subtype of `}<strong parentName="li"><em parentName="strong">{`string`}</em></strong>{` called `}<strong parentName="li"><em parentName="strong">{`ValidEmail`}</em></strong>{`, such that
`}<strong parentName="li"><em parentName="strong">{`string`}</em></strong>{` is not assignable to `}<strong parentName="li"><em parentName="strong">{`ValidEmail`}</em></strong>{`.`}</li>
      <li parentName="ul">{`Hold already validated emails in a list of type `}<strong parentName="li"><em parentName="strong">{`ValidEmails`}</em></strong>{`.`}</li>
      <li parentName="ul">{`Now you can’t push a string to a list of already validated emails ✨`}</li>
      <li parentName="ul">{`Change the type of `}<inlineCode parentName="li">{`sendEmail`}</inlineCode>{` function from `}<inlineCode parentName="li">{`(email: string) => void`}</inlineCode>{` to
`}<inlineCode parentName="li">{`(email: ValidEmail) => void*`}</inlineCode>{`. `}<br parentName="li"></br>
        {`It doesn’t make sense to send an email to `}<inlineCode parentName="li">{`"🦄🐵💣"`}</inlineCode>{` which is a perfectly valid
string.`}</li>
    </ul>
    <p>{`( `}{`*`}{` ) or IO, Result, choose your favorite.`}</p>
    <h4 {...{
      "id": "yeah-right-but-how-can-i-create-this-validemail-type",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#yeah-right-but-how-can-i-create-this-validemail-type",
        "aria-label": "yeah right but how can i create this validemail type permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Yeah right, but how can I create this “ValidEmail” type?`}</h4>
    <p>{`However you want! It’s just an idea from type theory and you can implement
it in your favorite way. Few ideas:`}</p>
    <ul>
      <li parentName="ul">{`you can go full OOP and extend a `}<strong parentName="li"><em parentName="strong">{`String`}</em></strong>{`,`}</li>
      <li parentName="ul">{`use `}<a parentName="li" {...{
          "href": "https://github.com/hasparus/nom-ts"
        }}>{`nominal`}</a>{` `}
        <a parentName="li" {...{
          "href": "https://michalzalecki.com/nominal-typing-in-typescript/#approach-4-intersection-types-and-brands"
        }}>{`typing`}</a>{`
and leave no runtime trail `}<em parentName="li">{`(my favorite option)`}</em>{`,`}</li>
      <li parentName="ul">{`put the string into a
`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Value_object"
        }}>{`value object`}</a>{`, because
`}<strong parentName="li"><em parentName="strong">{`ValidEmail`}</em></strong>{` doesn’t even have to be a subtype of `}<strong parentName="li"><em parentName="strong">{`string`}</em></strong>{`. `}<br parentName="li"></br>
        {`The key is that `}<strong parentName="li"><em parentName="strong">{`string`}</em></strong>{` is not assignable to `}<strong parentName="li"><em parentName="strong">{`ValidEmail`}</em></strong>{`, because
we want to ensure validation.`}</li>
    </ul>
    <h3 {...{
      "id": "user-defined-type-guards",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#user-defined-type-guards",
        "aria-label": "user defined type guards permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`User defined type guards`}</h3>
    <p>{`We can use TypeScript’s
`}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/docs/handbook/advanced-types.html#using-type-predicates"
      }}>{`user defined type guards`}</a>{`
to tell the compiler that our predicate checks the type and this is exactly
what we’re interested in when we talk about refinements.`}</p>
    <p>{`Let’s empower our `}<inlineCode parentName="p">{`isValidEmail`}</inlineCode>{` predicate by changing its signature from
`}<inlineCode parentName="p">{`(s: string) => boolean`}</inlineCode>{` to `}<inlineCode parentName="p">{`(s: string) => s is ValidEmail`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "takeaways",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#takeaways",
        "aria-label": "takeaways permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Refinements are not implemented in TypeScript, but you can make them in
userspace.`}</li>
      <li parentName="ul">{`You can use nominal typing to make sure your refinements have no runtime
trail (except predicate checks).`}</li>
      <li parentName="ul">{`You can use them to encode facts about your data in the type system.`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "further-reading",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#further-reading",
        "aria-label": "further reading permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Further reading`}</h2>
    <p><em parentName="p">{`This is mostly a reading list for future me, but I hope you can also find
it interesting.`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/fthomas/refined"
          }}>{`refined`}</a>{` for Scala sounds really
interesting and with `}<a parentName="p" {...{
            "href": "https://www.scala-js.org/"
          }}>{`ScalaJs`}</a>{` I could target
the same platforms as TypeScript.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`”`}<a parentName="p" {...{
            "href": "http://kmcallister.github.io/talks/rust/2015-dependent-types/slides.html"
          }}>{`A taste of dependent types`}</a>{`”
by Keegan McAllister`}</p>
      </li>
    </ul>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      